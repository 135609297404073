<template>
  <div>
    <div class="picture-approval">
      <div class="picture-box" v-for="(item) in imageList" :key="item.image_url">
        <div :class="['delete-tag', item.delete_tag ? 'not-delete' : '']">{{ item.delete_tag ? '已删除' : '未删除' }}
        </div>
        <img class="picture-box-img" :src="item.image_url" alt=""/>
        <div class="delete-btn" @click="handleDelete(item)">删除</div>
      </div>
    </div>
    <div class="pagination">
      <el-pagination
        :page-size="pagination.pageSizes"
        v-model:current-page="pagination.currentPage"
        background
        layout="prev, pager, next"
        @current-change="handlePageChange"
        :total="pagination.total"/>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'PictureApproval',
  data() {
    return {
      imageList: [],
      queryParams: {},
      pagination: {
        pageSizes: 20,
        currentPage: 1,
        total: 0
      }
    }
  },
  created() {
    // watch 路由的参数，以便再次获取数据
    this.$watch(
      () => this.$route.query,
      (value) => {
        if (value.token) {
          this.initPage()
        }
      },
      // 组件创建完后获取数据，
      // 此时 data 已经被 observed 了
      { immediate: true }
    )
  },
  methods: {
    initPage() {
      this.queryParams = this.$route.query
      // console.log('1111', this.queryParams)
      this.pagination.currentPage = this.queryParams.pageNo
      this.getList()
    },
    async handleDelete(item) {
      if (!this.queryParams.token) {
        alert('需要登录')
      }
      await axios.get(`https://apis.lihuanyu.com/replicate-web/delete-pic?token=${this.queryParams.token}&picId=${item.id}`)
      await this.getList()
    },
    async getList() {
      if (!this.queryParams.token) {
        alert('需要登录')
      }
      const pageNo = this.pagination.currentPage
      const response = await axios.get(`https://apis.lihuanyu.com/replicate-web/all-pic?token=${this.queryParams.token}&pageNo=${pageNo}`)
      const data = response.data && response.data.data && response.data.data || {}
      this.imageList = data.items || []
      this.pagination = {
        ...this.pagination,
        total: (data.meta && data.meta.totalItems) || 0
      }
    },
    handlePageChange(val) {
      this.pagination = {
        ...this.pagination,
        currentPage: val
      }
      this.getList()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.picture-approval {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.picture-box {
  width: 16%;
  box-sizing: border-box;
  border: 1px solid #000;
  margin: 2%;
}

.picture-box-img {
  width: 100%;
}

.delete-tag {
  background: #c8f5c3;
  color: #666;
  height: 30px;
}

.not-delete {
  background: #e17e7e;
}

.delete-btn {
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  background: #d9d3d3;
}

.pagination {
  padding: 20px;
  display: flex;
  justify-content: flex-end;
}
</style>
